
import { defineComponent, inject } from 'vue'
import { useDailyReport } from '@/composable/useDailyReport'
import DailyReportExternalTrafficTableRow from './DailyReportExternalTrafficTableRow.vue'
import {
  DailyReportExternalTrafficData,
  dailyReportExternalTrafficDataKey,
} from '@/pages/DailyReportExternalPreview.vue'

export default defineComponent({
  name: 'DailyReportExternalTrafficTable',

  components: { DailyReportExternalTrafficTableRow },

  setup() {
    const { data, isPrintMode, expandedTrafficRows } = useDailyReport()
    const colStyle = 'white-space: pre-wrap; word-break: break-all;'

    const columns = inject<DailyReportExternalTrafficData>(
      dailyReportExternalTrafficDataKey
    ) as DailyReportExternalTrafficData

    return {
      data,
      columns,
      isPrintMode,
      colStyle,
      expandedTrafficRows,
    }
  },
})
