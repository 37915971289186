<template>
  <div class="q-pa-md">
    <q-table
      hide-bottom
      dense
      square
      :rows-per-page-options="[0]"
      :rows="data.trafficV2.rows"
      class="dailyReportTrafficTable"
      separator="cell"
      :bordered="isPrintMode"
      :flat="isPrintMode"
    >
      <template v-slot:header="props">
        <q-tr :props="props">
          <q-th style="text-align: left">
            <div>Trafiken<br />Operativa händelser</div>
          </q-th>
          <q-th
            v-for="(col, $i) of columns.columns_top"
            :key="$i"
            :colspan="col.colspan"
          >
            <div>
              {{ col.label }}
            </div>
          </q-th>
        </q-tr>
        <q-tr :props="props">
          <q-th style="text-align: left; width: 200px">
            <q-toggle
              label="Expandera alla"
              color="white"
              v-model="expandedTrafficRows"
            />
          </q-th>
          <q-th
            v-for="(col, $i) of columns.columns_bottom"
            :key="$i"
            :style="col.style"
          >
            <div :style="colStyle">
              {{ col.label_custom }}
            </div>
          </q-th>
        </q-tr>
      </template>

      <template v-slot:body="props">
        <DailyReportExternalTrafficTableRow
          :row="props.row"
          :level="0"
          :columns="columns.columns_bottom"
        />
      </template>
    </q-table>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue'
import { useDailyReport } from '@/composable/useDailyReport'
import DailyReportExternalTrafficTableRow from './DailyReportExternalTrafficTableRow.vue'
import {
  DailyReportExternalTrafficData,
  dailyReportExternalTrafficDataKey,
} from '@/pages/DailyReportExternalPreview.vue'

export default defineComponent({
  name: 'DailyReportExternalTrafficTable',

  components: { DailyReportExternalTrafficTableRow },

  setup() {
    const { data, isPrintMode, expandedTrafficRows } = useDailyReport()
    const colStyle = 'white-space: pre-wrap; word-break: break-all;'

    const columns = inject<DailyReportExternalTrafficData>(
      dailyReportExternalTrafficDataKey
    ) as DailyReportExternalTrafficData

    return {
      data,
      columns,
      isPrintMode,
      colStyle,
      expandedTrafficRows,
    }
  },
})
</script>

<style lang="scss">
.dailyReportTrafficTable {
  .q-table__top {
    background-color: rgb(0, 165, 64);
    color: white;
  }

  thead tr th {
    background-color: #00a540;
    color: white;
  }
}
</style>

<style lang="scss" module>
.totalRow {
  background-color: rgba(#00a540, 0.1);
  font-weight: 600;
}
</style>
