<template>
  <div class="q-pa-md">
    <q-table
      hide-bottom
      dense
      title="Fordonsläget"
      square
      class="dailyReportVehicleStatusTable"
      separator="cell"
      :rows-per-page-options="[0]"
      :rows="vehicleStatus"
      :columns="columns"
      :visible-columns="visibleColumns"
      :bordered="isPrintMode"
      :flat="isPrintMode"
    >
      <template v-slot:body-cell-comment="props">
        <q-td :props="props" style="padding: 0">
          <div class="flex items-end" v-if="!isPrintMode">
            <q-input
              style="flex: 1"
              readonly
              borderless
              dense
              type="textarea"
              autogrow
              :input-style="{ paddingLeft: '8px' }"
              :model-value="`${props.row.period_comment}${
                props.row.period_comment
                  ? `\r${props.row.comment}`
                  : props.row.comment
              }`"
            />
          </div>
          <div v-else style="padding: 8px; white-space: pre-wrap">
            {{
              `${props.row.period_comment}${
                props.row.period_comment
                  ? `\r${props.row.comment}`
                  : props.row.comment
              }`
            }}
          </div>
        </q-td>
      </template>

      <template v-slot:body-cell-_summary="props">
        <q-td :props="props">
          <div
            class="flex items-center justify-end"
            v-if="summaryGroupMap[props.row.vehicleTypeGroupName]"
          >
            <q-badge
              :style="{
                background:
                  summaryGroupMap[props.row.vehicleTypeGroupName].statusColor,
              }"
            />
            <div class="q-ml-sm" style="min-width: 20px">
              {{ summaryGroupMap[props.row.vehicleTypeGroupName].available }}
            </div>
          </div>
        </q-td>
      </template>
    </q-table>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import { useDailyReport } from '@/composable/useDailyReport'
import { QTable } from 'quasar'
import { DailyReportVehicleStatus } from '@/types/daily-report-vehicle-status'
import { useVehicle } from '@/composable/useVehicle'
import orderBy from 'lodash.orderby'
import { useProfile } from '@/composable/useProfile'
import { WithdrawalDashboardSummary } from '@/types/withdrawal-dashboard-summary'

export default defineComponent({
  name: 'DailyReportExternalVehicleStatusTable',

  props: {
    summary: {
      type: Array as PropType<WithdrawalDashboardSummary[]>,
      required: true,
    },
  },

  setup(props) {
    const { data, updateVehicleStatus, isPrintMode } = useDailyReport()
    const { data: vehicleData } = useVehicle()
    const { can } = useProfile()
    const columns: QTable['columns'] = [
      {
        align: 'left',
        name: 'vehicleTypeGroupName',
        label: 'Fordonstyp',
        field: 'vehicleTypeGroupName',
        style: 'width: 100px;',
      },
      {
        align: 'left',
        name: 'comment',
        label: 'Kommentar',
        field: 'comment',
      },
      {
        align: 'right',
        name: '_summary',
        label: 'Tillgängliga fordon',
        field: '_summary',
        style: 'width: 130px',
      },
      // {
      //   align: 'right',
      //   name: 'availablePrint',
      //   label: 'Tillgängliga',
      //   field: 'availablePrint',
      //   style: 'min-width: 200px',
      // },
      // {
      //   align: 'right',
      //   name: 'available',
      //   label: 'Fordon som är avställda',
      //   field: 'available',
      //   style: 'min-width: 200px',
      // },
      // {
      //   align: 'right',
      //   name: 'required',
      //   label: 'Fordon som kan vara avställda',
      //   field: 'required',
      //   style: 'min-width: 200px',
      // },
    ]

    const summaryGroupMap = computed(() => {
      return props.summary.reduce<{
        [name: string]: WithdrawalDashboardSummary
      }>((acc, summary) => {
        if (!summary.parent) {
          acc[summary.name] = summary
        }

        return acc
      }, {})
    })

    function onUpdateAvailable(v: string, item: DailyReportVehicleStatus) {
      const body = {
        uuid: item.uuid,
        date: item.date,
        vehicleTypeGroupUuid: item.vehicleTypeGroupUuid,
        required: item.required,
        available: parseInt(v, 10),
      }
      updateVehicleStatus(body)
    }

    function onUpdateRequired(v: string, item: DailyReportVehicleStatus) {
      const body = {
        uuid: item.uuid,
        date: item.date,
        vehicleTypeGroupUuid: item.vehicleTypeGroupUuid,
        required: parseInt(v, 10),
        available: item.available,
      }
      updateVehicleStatus(body)
    }

    const vehicleStatus = computed(() => {
      const parsedData = data.value.vehicleStatus.map((vehicleStatus) => {
        return {
          ...vehicleStatus,
          availablePrint:
            vehicleData.value.filter((vehicle) => {
              return (
                vehicleStatus.vehicleTypeGroupUuid ===
                vehicle?.vehicleType?.vehicleTypeGroupUuid
              )
            }).length - vehicleStatus.available,
        }
      })

      return orderBy(parsedData, ['vehicleTypeGroupName'], ['asc'])
    })

    const visibleColumns = computed(() => {
      const filteredColumns: string[] = columns.map((x) => x.field as string)
      return isPrintMode.value
        ? filteredColumns.filter(
            (x: string) => !['required', 'available'].includes(x)
          )
        : filteredColumns.filter((x) => x !== 'availablePrint')
    })

    return {
      can,
      data,
      columns,
      onUpdateAvailable,
      onUpdateRequired,
      vehicleStatus,
      visibleColumns,
      isPrintMode,
      summaryGroupMap,
    }
  },
})
</script>

<style lang="scss">
.dailyReportVehicleStatusTable {
  .q-table__top {
    background-color: #00a540;
    color: white;
  }

  thead tr:first-child th {
    background-color: #00a540;
    color: white;
  }

  // tbody tr:first-child td {
  //   background-color: rgba(#00a540, 0.2);
  // }
}

.dailyReportRightAlignInput input {
  text-align: right;
}
</style>
