
import { computed, defineComponent } from 'vue'
import QTable from 'quasar/src/components/table/QTable.js';
import format from 'date-fns/format'
import { useDailyReport } from '@/composable/useDailyReport'
import { DailyReportEvent } from '@/types/daily-report-event'
import { DailyReportDeviation } from '@/types/daily-report-deviation'

export default defineComponent({
  name: 'DailyReportExternalDeviationTable',

  props: {
    title: String,
    data: {
      type: Array,
      required: true,
    },
  },

  setup() {
    const { data, isPrintMode } = useDailyReport()
    const columns: QTable['columns'] = [
      {
        align: 'left',
        name: 'advertised',
        label: 'Tåg',
        field: 'advertised',
        style: 'width: 100px',
      },
      {
        align: 'left',
        name: 'from',
        label: 'Från',
        field: 'from',
        style: 'width: 100px',
      },
      {
        align: 'left',
        name: 'to',
        label: 'Till',
        field: 'to',
        style: 'width: 100px',
      },
      {
        align: 'left',
        name: 'departure',
        label: 'Avg.',
        field: 'departure',
        style: 'width: 100px',
      },
      {
        align: 'left',
        name: 'arrival',
        label: 'Ank.',
        field: 'arrival',
        style: 'width: 100px',
      },
      {
        align: 'left',
        name: 'type',
        label: 'Händelsetyp',
        field: 'type',
      },
      {
        align: 'left',
        name: 'reason',
        label: 'Orsak 1',
        field: 'reason',
        style: 'width: 200px',
      },
      {
        align: 'left',
        name: 'reason2',
        label: 'Orsak 2',
        field: 'reason2',
        style: 'width: 200px',
      },
    ]

    const eventMap = computed(() => {
      return data.value.event.reduce<{ [key: string]: DailyReportEvent[] }>(
        (acc, event) => {
          if (!event.luppTracks.length) {
            acc['Bana saknas'].push(event)
          }
          event.luppTracks.forEach((luppTrack) => {
            if (!acc[luppTrack.description]) {
              acc[luppTrack.description] = []
            }
            acc[luppTrack.description].push(event)
          })
          return acc
        },
        { 'Bana saknas': [] }
      )
    })

    const formatTime = (row: DailyReportDeviation, value: string | null) => {
      if (row.type === 'Försening') {
        return value ? `${value} min` : ''
      }
      return value ? format(new Date(value), 'HH:mm') : ''
    }

    return { columns, eventMap, isPrintMode, formatTime }
  },
})
