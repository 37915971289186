import { render } from "./DailyReportExternalTrafficTableRow.vue?vue&type=template&id=4d0c51ae"
import script from "./DailyReportExternalTrafficTableRow.vue?vue&type=script&lang=ts"
export * from "./DailyReportExternalTrafficTableRow.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QTr from 'quasar/src/components/table/QTr.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QTr,QTd,QBtn});
