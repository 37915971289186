<template>
  <q-toolbar class="q-py-sm bg-white hideOnPrint">
    <h6 class="q-ma-none q-pa-sm">
      Dygnsrapport {{ transportAuthority }} | {{ date }}
    </h6>
    <q-space />
    <div
      class="q-mr-md q-px-sm"
      :style="{
        border: '1px solid rgba(0,0,0,.24)',
        borderRadius: '4px',
        height: '36px',
        display: 'flex',
        alignItems: 'center',
      }"
    >
      Antal
      <q-toggle
        false-value="count"
        label="Procent"
        true-value="percentage"
        color="primary"
        v-model="percentageOrCount"
      />
    </div>
    <AppFieldDate
      :square="false"
      dense
      v-model="date"
      class="dailyReportDateInput q-mr-md"
      hide-bottom-space
    />

    <q-btn-dropdown label="Filter banor" class="q-mr-md" outline color="grey-7">
      <template #label>
        <q-badge color="primary" floating>
          {{ filterTrackCount }}
        </q-badge>
      </template>
      <q-list dense>
        <q-item-label class="q-py-xs bg-grey-2" header>Bangrupper</q-item-label>
        <q-separator />
        <q-item
          clickable
          tag="label"
          v-for="(col, $i) of filteredColumnsTop"
          :key="`top-${$i}`"
        >
          <q-item-section avatar>
            <q-checkbox
              v-model="col.visible"
              @update:model-value="(v) => onTrackGroupUpdate(v, col)"
            />
          </q-item-section>
          <q-item-section>
            <q-item-label>{{ col.label }}</q-item-label>
          </q-item-section>
        </q-item>
        <q-separator />
        <q-item-label class="q-py-xs bg-grey-2" header>Banor</q-item-label>
        <q-separator />
        <q-item
          tag="label"
          clickable
          v-for="(col, $i) of filteredColumnsBottom"
          :key="$i"
        >
          <q-item-section avatar>
            <q-checkbox v-model="col.visible" />
          </q-item-section>
          <q-item-section>
            <q-item-label>{{ col.label }}</q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-btn-dropdown>

    <q-btn-dropdown
      label="Filter händelsetyp"
      class="q-mr-md"
      outline
      color="grey-7"
    >
      <template #label>
        <q-badge color="primary" floating>
          {{ filterEvenTypeCount }}
        </q-badge>
      </template>
      <q-list dense>
        <q-item
          tag="label"
          clickable
          v-for="eventType of eventTypes"
          :key="eventType"
        >
          <q-item-section avatar>
            <q-checkbox v-model="selectedFilterEventType" :val="eventType" />
          </q-item-section>
          <q-item-section>
            <q-item-label>{{ eventType }}</q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-btn-dropdown>
    <q-btn-group outline v-if="isDailyReportInternal">
      <q-btn
        outline
        color="grey-7"
        label="Skriv ut"
        icon-right="mdi-printer"
        @click="onPrint"
      />
    </q-btn-group>
  </q-toolbar>
  <q-separator />
</template>

<script lang="ts">
import { computed, defineComponent, watch, inject } from 'vue'
import AppFieldDate from '@/components/AppFieldDate.vue'
import { useDailyReport } from '@/composable/useDailyReport'
import { useLupp } from '@/composable/useLupp'
import { isValid, subDays, format } from 'date-fns'
import { DailyReportTrack } from '@/types/daily-report-deviation'
import { useProfile } from '@/composable/useProfile'
import { useWithdrawalDashboard } from '@/composable/useWithdrawalDashboard'
import {
  DailyReportExternalTrafficData,
  dailyReportExternalTrafficDataKey,
} from '@/pages/DailyReportExternalPreview.vue'
import { useUserSettings } from '@/composable/useUserSettings'
import { useEvent } from '@/composable/useEvent'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'DailyReportExternalToolBar',

  components: {
    AppFieldDate,
  },

  setup() {
    const route = useRoute()
    const isDailyReportInternal = route.name === 'daily-report-preview'
    const initalDate = isDailyReportInternal
      ? format(new Date(), 'yyyy-MM-dd')
      : format(subDays(new Date(), 1), 'yyyy-MM-dd')
    const { getCurrentProject, currentProject } = useProfile()
    const {
      getAllTrafficV2,
      getAllVehicleStatus,
      getAllDeviation,
      getAllEvent,
      date,
      isPrintMode,
      data,
      expandedTrafficRows,
      selectedFilterEventType,
      percentageOrCount,
    } = useDailyReport(initalDate)

    const { fetchAll, fetchDailyReportEventSection } = useEvent()
    const columns = inject<DailyReportExternalTrafficData>(
      dailyReportExternalTrafficDataKey
    ) as DailyReportExternalTrafficData

    const { getAllTracks } = useLupp()

    const { fetchSummary } = useWithdrawalDashboard()

    watch(
      () => selectedFilterEventType.value,
      (value) => {
        settings.value[project].dailyReportExternalV2.eventTypes = value
      }
    )

    const transportAuthority =
      getCurrentProject()?.transportAuthority || 'extern'
    const project = currentProject.value?.name as string
    const { settings } = useUserSettings()
    watch(
      date,
      (v) => {
        if (v.length !== 10 || !isValid(new Date(v))) return

        getAllTrafficV2(v)
        getAllVehicleStatus(v)
        getAllDeviation(v).then((data) => {
          selectedFilterEventType.value = getUniqueDeviationTypes(data).filter(
            (x) => x !== 'Försening'
          )

          selectedFilterEventType.value =
            settings.value[project].dailyReportExternalV2.eventTypes
        })
        getAllEvent(v)
        getAllTracks()
        fetchSummary({
          from: v,
          to: v,
          time: '07:00',
        })

        if (isDailyReportInternal) {
          fetchDailyReportEventSection({ from: v, to: v })
          fetchAll({
            from: v,
            to: v,
          })
        }
      },
      {
        immediate: true,
      }
    )

    function getUniqueDeviationTypes(deviations: DailyReportTrack[]) {
      return [
        ...new Set(deviations.flatMap((x) => x.deviations).map((x) => x.type)),
      ]
    }

    const eventTypes = computed(() => {
      return getUniqueDeviationTypes(data.value.deviation)
    })

    function onPrint() {
      expandedTrafficRows.value = true
      isPrintMode.value = true
      document.body.classList.add('printMode')
      document.body.classList.add('printLandscape')
      setTimeout(() => {
        window.print()
        setTimeout(() => {
          isPrintMode.value = false
          document.body.classList.remove('printMode')
          document.body.classList.remove('printLandscape')
        }, 100)
      }, 200)
    }

    const columnsBottomLabels = (label: string) => {
      return (
        label.length &&
        !label.includes('All tågtrafik') &&
        !label.includes('total')
      )
    }

    const filteredColumnsBottom = computed(() => {
      return columns.value.columns_bottom.filter((x) => {
        return columnsBottomLabels(x.label)
      })
    })

    const filteredColumnsTop = computed(() => {
      return columns.value.columns_top.filter((x) => {
        return x.label && !x.label.includes('Total')
      })
    })

    const onTrackGroupUpdate = (value: boolean, col: { label: string }) => {
      const columns_bottom = columns.value.columns_bottom.filter(
        (x) => columnsBottomLabels(x.label) && x.tag === col.label
      )

      columns_bottom.forEach((x) => {
        x.visible = value
      })
    }

    watch(
      () => filteredColumnsBottom.value,
      () => {
        filteredColumnsTop.value.forEach((x) => {
          const tracks = filteredColumnsBottom.value.filter(
            (t) => t.tag === x.label
          )
          x.visible = tracks.every((x) => x.visible)
            ? true
            : tracks.every((x) => !x.visible)
            ? false
            : null
        })

        settings.value[project].dailyReportExternalV2.tracks =
          filteredColumnsBottom.value.map(({ label, visible }) => ({
            label,
            visible,
          }))
      },
      {
        deep: true,
      }
    )

    const filterTrackCount = computed(() => {
      return filteredColumnsBottom.value.filter((x) => x.visible).length
    })

    const filterEvenTypeCount = computed(() => {
      return selectedFilterEventType.value.length
    })

    watch(
      () => percentageOrCount.value,
      (value) => {
        settings.value[project].dailyReportExternalV2.show_percent = value
      }
    )

    percentageOrCount.value =
      settings.value[project].dailyReportExternalV2.show_percent

    return {
      date,
      onPrint,
      selectedFilterEventType,
      eventTypes,
      transportAuthority,
      percentageOrCount,
      columns,
      filteredColumnsBottom,
      filteredColumnsTop,
      onTrackGroupUpdate,
      filterTrackCount,
      filterEvenTypeCount,
      project,
      settings,
      isDailyReportInternal,
    }
  },
})
</script>

<style>
.dailyReportDateInput .q-field__control {
  height: 36px !important;
}
</style>
