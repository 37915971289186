<template>
  <q-tr :style="level === 0 && { backgroundColor: 'rgba(0, 165, 64, 0.1)' }">
    <q-td
      :style="{
        paddingLeft: `${level * 28 + 16}px`,
        cursor: 'pointer',
      }"
      @click="expand = !expand"
    >
      <q-btn
        size="sm"
        flat
        round
        dense
        :icon="expand ? 'mdi-menu-up' : 'mdi-menu-down'"
        v-if="row.children && row.children.length"
      />
      {{ row.label }}
    </q-td>
    <q-td
      v-for="col in columns"
      :key="col.label"
      style="text-align: right"
      :style="
        col.label.toLowerCase().includes('totalt') && {
          backgroundColor: 'rgba(0, 165, 64, 0.3)',
        }
      "
    >
      <template v-if="col.visible">
        {{
          row.data?.find((x) => x.name === col.label)?.[
            percentageOrCountValue
          ] || ''
        }}
      </template>
    </q-td>
  </q-tr>
  <template v-if="row.children && expand">
    <DailyReportExternalTrafficTableRow
      :row="child"
      :columns="columns"
      :level="level + 1"
      v-for="(child, $i) of row.children"
      :key="$i"
    />
  </template>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, computed, watch } from 'vue'
import { DailyReportTrafficV2 } from '@/types/daily-report-traffic-v2'
import { useDailyReport } from '@/composable/useDailyReport'
import { useUserSettings } from '@/composable/useUserSettings'
import { useProfile } from '@/composable/useProfile'

export default defineComponent({
  name: 'DailyReportExternalTrafficTableRow',

  props: {
    row: {
      type: Object as PropType<DailyReportTrafficV2>,
      required: true,
    },
    columns: {
      type: Array as PropType<{ label: string; visible: boolean }[]>,
      required: true,
    },
    level: {
      type: Number,
      required: true,
    },
  },

  setup(props) {
    const { currentProject } = useProfile()
    const { percentageOrCount, expandedTrafficRows } = useDailyReport()
    const { settings } = useUserSettings()
    const project = currentProject.value?.name as string
    const percentageOrCountValue = computed(() => {
      if (props.level === 0) return 'percentage'
      return percentageOrCount.value as 'count' | 'percentage'
    })

    const expand = ref(
      settings.value[project].dailyReportExternalV2.expanded_traffic_row?.[
        props.row.label
      ] || false
    )

    watch(
      () => expand.value,
      (value) => {
        if (
          !settings.value[project].dailyReportExternalV2.expanded_traffic_row
        ) {
          settings.value[project].dailyReportExternalV2.expanded_traffic_row =
            {}
        }
        settings.value[project].dailyReportExternalV2.expanded_traffic_row[
          props.row.label
        ] = value
      }
    )

    watch(
      () => expandedTrafficRows.value,
      (value) => {
        expand.value = value
      }
    )
    return { expand, percentageOrCountValue }
  },
})
</script>

<style scoped></style>
