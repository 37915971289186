import { render } from "./DailyReportExternalVehicleStatusTable.vue?vue&type=template&id=5aa5834e"
import script from "./DailyReportExternalVehicleStatusTable.vue?vue&type=script&lang=ts"
export * from "./DailyReportExternalVehicleStatusTable.vue?vue&type=script&lang=ts"

import "./DailyReportExternalVehicleStatusTable.vue?vue&type=style&index=0&id=5aa5834e&lang=scss"
script.render = render

export default script
import QTable from 'quasar/src/components/table/QTable.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QTable,QTd,QInput,QBadge});
