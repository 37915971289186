
import { defineComponent, PropType, ref, computed, watch } from 'vue'
import { DailyReportTrafficV2 } from '@/types/daily-report-traffic-v2'
import { useDailyReport } from '@/composable/useDailyReport'
import { useUserSettings } from '@/composable/useUserSettings'
import { useProfile } from '@/composable/useProfile'

export default defineComponent({
  name: 'DailyReportExternalTrafficTableRow',

  props: {
    row: {
      type: Object as PropType<DailyReportTrafficV2>,
      required: true,
    },
    columns: {
      type: Array as PropType<{ label: string; visible: boolean }[]>,
      required: true,
    },
    level: {
      type: Number,
      required: true,
    },
  },

  setup(props) {
    const { currentProject } = useProfile()
    const { percentageOrCount, expandedTrafficRows } = useDailyReport()
    const { settings } = useUserSettings()
    const project = currentProject.value?.name as string
    const percentageOrCountValue = computed(() => {
      if (props.level === 0) return 'percentage'
      return percentageOrCount.value as 'count' | 'percentage'
    })

    const expand = ref(
      settings.value[project].dailyReportExternalV2.expanded_traffic_row?.[
        props.row.label
      ] || false
    )

    watch(
      () => expand.value,
      (value) => {
        if (
          !settings.value[project].dailyReportExternalV2.expanded_traffic_row
        ) {
          settings.value[project].dailyReportExternalV2.expanded_traffic_row =
            {}
        }
        settings.value[project].dailyReportExternalV2.expanded_traffic_row[
          props.row.label
        ] = value
      }
    )

    watch(
      () => expandedTrafficRows.value,
      (value) => {
        expand.value = value
      }
    )
    return { expand, percentageOrCountValue }
  },
})
