<template>
  <div class="q-pa-md">
    <q-table
      hide-bottom
      dense
      :title="title"
      square
      :rows-per-page-options="[0]"
      :rows="data"
      :columns="columns"
      class="dailyReportDeviationTable"
      separator="cell"
      :bordered="isPrintMode"
      :flat="isPrintMode"
    >
      <template v-slot:top>
        <div class="full-width q-table__title q-px-md q-py-sm">{{ title }}</div>
        <template v-if="eventMap[title] && eventMap[title].length">
          <div
            class="full-width q-px-md"
            style="background: white; color: black"
            v-for="(event, $i) in eventMap[title]"
            :key="$i"
          >
            <p class="text-weight-bold q-pt-md" style="margin-bottom: 0px">
              {{ event.from }} - {{ event.to }}
            </p>
            <p style="margin-top: 0px; white-space: pre-wrap">
              {{ event.description }}
            </p>
          </div>
        </template>
      </template>

      <template v-slot:body-cell-arrival="props">
        <q-td :props="props">
          {{ formatTime(props.row, props.value) }}
        </q-td>
      </template>

      <template v-slot:body-cell-departure="props">
        <q-td :props="props">
          {{ formatTime(props.row, props.value) }}
        </q-td>
      </template>

      <!-- <template v-slot:top-row v-if="eventMap[title] && eventMap[title].length">
        <q-tr v-for="(event, $i) in eventMap[title]" :key="$i">
          <q-td colspan="100%">
            <p class="text-weight-bold q-pt-md" style="margin-bottom: 0px">
              {{ event.from }} - {{ event.to }}
            </p>
            <p style="margin-top: 0px; white-space: pre-wrap">
              {{ event.description }}
            </p>
          </q-td>
        </q-tr>
      </template> -->
    </q-table>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { QTable } from 'quasar'
import format from 'date-fns/format'
import { useDailyReport } from '@/composable/useDailyReport'
import { DailyReportEvent } from '@/types/daily-report-event'
import { DailyReportDeviation } from '@/types/daily-report-deviation'

export default defineComponent({
  name: 'DailyReportExternalDeviationTable',

  props: {
    title: String,
    data: {
      type: Array,
      required: true,
    },
  },

  setup() {
    const { data, isPrintMode } = useDailyReport()
    const columns: QTable['columns'] = [
      {
        align: 'left',
        name: 'advertised',
        label: 'Tåg',
        field: 'advertised',
        style: 'width: 100px',
      },
      {
        align: 'left',
        name: 'from',
        label: 'Från',
        field: 'from',
        style: 'width: 100px',
      },
      {
        align: 'left',
        name: 'to',
        label: 'Till',
        field: 'to',
        style: 'width: 100px',
      },
      {
        align: 'left',
        name: 'departure',
        label: 'Avg.',
        field: 'departure',
        style: 'width: 100px',
      },
      {
        align: 'left',
        name: 'arrival',
        label: 'Ank.',
        field: 'arrival',
        style: 'width: 100px',
      },
      {
        align: 'left',
        name: 'type',
        label: 'Händelsetyp',
        field: 'type',
      },
      {
        align: 'left',
        name: 'reason',
        label: 'Orsak 1',
        field: 'reason',
        style: 'width: 200px',
      },
      {
        align: 'left',
        name: 'reason2',
        label: 'Orsak 2',
        field: 'reason2',
        style: 'width: 200px',
      },
    ]

    const eventMap = computed(() => {
      return data.value.event.reduce<{ [key: string]: DailyReportEvent[] }>(
        (acc, event) => {
          if (!event.luppTracks.length) {
            acc['Bana saknas'].push(event)
          }
          event.luppTracks.forEach((luppTrack) => {
            if (!acc[luppTrack.description]) {
              acc[luppTrack.description] = []
            }
            acc[luppTrack.description].push(event)
          })
          return acc
        },
        { 'Bana saknas': [] }
      )
    })

    const formatTime = (row: DailyReportDeviation, value: string | null) => {
      if (row.type === 'Försening') {
        return value ? `${value} min` : ''
      }
      return value ? format(new Date(value), 'HH:mm') : ''
    }

    return { columns, eventMap, isPrintMode, formatTime }
  },
})
</script>

<style lang="scss">
.dailyReportDeviationTable {
  .q-table__top {
    background-color: #00a540;
    color: white;
    padding: 0;
  }

  thead tr:first-child th {
    background-color: #00a540;
    color: white;
  }
}
</style>
